<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="listQuery.shop_ids" multiple collapse-tags placeholder="请选择店铺" style="width: 220px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker v-model="dateArr" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
      </el-date-picker>
      <el-select v-model="listQuery.order_type" placeholder="订单类型" style="width: 140px" clearable>
        <el-option label="食堂美食" :value="1"></el-option>
        <!-- <el-option label="舍购" :value="2"></el-option>
        <el-option label="夜猫店" :value="3"></el-option>
        <el-option label="零食盒子" :value="4"></el-option> -->
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>

    <div class="count-data">
      <div class="count-data-item">
        <div class="item-label">订单时间</div>
        <div class="item-value">{{count.time}}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">合计订单金额</div>
        <div class="item-value">{{count.order_sum}}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">合计订单数量</div>
        <div class="item-value">{{count.order_count}}</div>
      </div>
      <div class="count-data-item">
        <div class="item-label">合计退款金额</div>
        <div class="item-value">{{count.refund_sum}}</div>
      </div>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <!-- <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column> -->
      <el-table-column label="店铺名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop.shop_name }}
        </template>
      </el-table-column>
      <el-table-column label="订单编号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="打印号" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop_count }}
        </template>
      </el-table-column>
      <el-table-column label="订单时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="商品名称" min-width="180" align="center">
        <template slot-scope="scope">
          <span v-for="(item,index) in scope.row.details" :key="index" class="goods_item">
            {{item.goods_name}}*{{item.goods_num}}
            <span v-if="index < scope.row.details.length - 1">&nbsp;|&nbsp;</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="销售数量" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.goods_num }}
        </template>
      </el-table-column>
      <el-table-column label="应收金额" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_price }}
        </template>
      </el-table-column>
      <el-table-column label="总优惠" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.discount_money }}
        </template>
      </el-table-column>
      <el-table-column label="系统优惠" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.discount_money_system }}
        </template>
      </el-table-column>
      <el-table-column label="平台优惠" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.discount_money_platform }}
        </template>
      </el-table-column>
      <el-table-column label="商家优惠" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.discount_money_shop }}
        </template>
      </el-table-column>
      <el-table-column label="实收金额" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_money }}
        </template>
      </el-table-column>
      <el-table-column label="退款金额" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_status == 2 ? scope.row.pay_money : 0 }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
var moment = require("moment");

export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      shops: [],
      list: null,
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        shop_ids: [],
        order_type: 1,
        start_date: "",
        end_date: ""
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        canteen_name: ""
      },
      dateArr: [moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
      count: {},
    };
  },
  created() {
    // this.getList();
    this.getShopList()
  },
  mounted() {},
  computed: {
    ...mapGetters(['schools','school_id']),
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal
      this.getShopList()
    },
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id
      if (!this.listQuery.school_id) {
        this.$message({
          type: "warning",
          message: "请选择学校"
        });
        return;
      }
      if (this.listQuery.shop_ids.length <= 0) {
        this.$message({
          type: "warning",
          message: "请选择店铺"
        });
        return;
      }
      if (!this.dateArr) {
        this.$message({
          type: "warning",
          message: "请选择日期"
        });
        return;
      } else {
        this.listQuery.start_date = this.dateArr[0];
        this.listQuery.end_date = this.dateArr[1];
      }
      if (!this.listQuery.order_type) {
        this.$message({
          type: "warning",
          message: "请选择订单类型"
        });
        return;
      }
      this.listLoading = true;
      request({
        url: "/api/backend/finance/bill",
        method: "post",
        data: this.listQuery
      }).then(response => {
        this.list = response.data.list.data;
        this.total = response.data.list.total;
        this.count = {
          ...response.data.count,
          time: `${this.listQuery.start_date} 00:00:00 至 ${this.listQuery.end_date} 23:59:59`
        }
        this.listLoading = false;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id
        }
      }).then(response => {
        this.shops = response.data.data;
        this.listQuery.shop_ids = this.shops.map((element)=>{
          return element.id
        })
        this.getList();
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.count-data {
  width: 100%;
  background: #f6f6f6;
  padding: 15px 0;
  display: flex;
  .count-data-item {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    .item-label {
      color: #666;
    }
    .item-value {
      color: #232323;
      font-weight: bold;
      margin-top: 5px;
    }
  }
}
</style>
